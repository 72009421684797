import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, vShow as _vShow, vModelCheckbox as _vModelCheckbox } from "vue"
import _imports_0 from '@/assets/images/icons/calender.png'
import _imports_1 from '@/assets/images/icons/travel-destination.svg'
import _imports_2 from '@/assets/images/icons/whatsapp-checkout.svg'
import _imports_3 from '@/assets/images/icons/mail-checkout.svg'


const _hoisted_1 = {
  key: 0,
  class: "checkout page-content"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "checkout-container main-container" }
const _hoisted_4 = { class: "checkout_row" }
const _hoisted_5 = { class: "page-title plain" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "checkout-block checkout-page-block travel-checkout" }
const _hoisted_8 = { class: "checkout-top" }
const _hoisted_9 = { class: "checkout-top__block" }
const _hoisted_10 = { class: "result-listing__item__block_top_row row align-items-center" }
const _hoisted_11 = { class: "partner-logo col-lg-3 col-md-12" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "partner-content col-lg-7 col-md-12" }
const _hoisted_14 = { class: "partner-content_title" }
const _hoisted_15 = { class: "mb-0" }
const _hoisted_16 = { class: "partner-content_bottom d-flex mb-4 row flex-nowrap policy-details-checkout" }
const _hoisted_17 = { class: "partner-content_bottom_item col-md-6 flex-nowrap me-0" }
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { class: "text mw-100" }
const _hoisted_20 = { class: "partner-content_bottom_item col-md-6 flex-nowrap me-0" }
const _hoisted_21 = { class: "title align-items-start" }
const _hoisted_22 = { class: "tooltip-content" }
const _hoisted_23 = { class: "partner-content_bottom_item cover-cost-block row flex-wrap" }
const _hoisted_24 = {
  key: 0,
  class: "col-lg-4 col-md-6 me-0"
}
const _hoisted_25 = { class: "right-buy-now text-center h-100 col-lg-2 col-md-12 pt-0 border-0 traveller_right_block" }
const _hoisted_26 = { class: "right-buy-now-top" }
const _hoisted_27 = { class: "premium_block" }
const _hoisted_28 = { class: "premium-title" }
const _hoisted_29 = { class: "price-title" }
const _hoisted_30 = { class: "mb-0" }
const _hoisted_31 = { class: "traveller_block" }
const _hoisted_32 = { class: "no_of_traveller" }
const _hoisted_33 = {
  key: 0,
  class: "share-icons"
}
const _hoisted_34 = { class: "p-0 d-flex align-items-center justify-content-center" }
const _hoisted_35 = { class: "icon" }
const _hoisted_36 = {
  key: 0,
  role: "alert",
  "aria-live": "assertive",
  "aria-atomic": "true",
  class: "toast custom-toast success-toast show",
  "data-bs-autohide": "false"
}
const _hoisted_37 = { class: "checkout-middle" }
const _hoisted_38 = { class: "partner-content_bottom row w-100" }
const _hoisted_39 = {
  key: 0,
  class: "partner-content_bottom_item col-lg-3 col-md-6 col-sm-12 me-0"
}
const _hoisted_40 = { class: "checkout-middle-footer" }
const _hoisted_41 = { class: "result-listing__item__block middle-footer-checkout" }
const _hoisted_42 = { class: "result-listing__item__block-bottom-top pb-0" }
const _hoisted_43 = {
  class: "summary m-0 p-0",
  style: {"box-shadow":"none"}
}
const _hoisted_44 = { class: "inner-title" }
const _hoisted_45 = { class: "mb-0" }
const _hoisted_46 = { class: "row" }
const _hoisted_47 = {
  key: 0,
  class: "col-lg-3 col-md-6 col-sm-6 special-features"
}
const _hoisted_48 = { class: "listing-ul" }
const _hoisted_49 = { style: {"margin-bottom":"0.5rem"} }
const _hoisted_50 = { class: "mw-100" }
const _hoisted_51 = {
  key: 0,
  class: "checkout-block-details checkout-bloc-custom"
}
const _hoisted_52 = { class: "checkout-bottom checkout-bottom-container" }
const _hoisted_53 = { class: "checkout-block-traveller-details" }
const _hoisted_54 = {
  key: 0,
  class: "row item-row"
}
const _hoisted_55 = { class: "mb-0" }
const _hoisted_56 = {
  key: 0,
  class: "vechile-details-list"
}
const _hoisted_57 = { class: "row form-block travel-date" }
const _hoisted_58 = { class: "col-lg-3 col-md-6 col-sm-6" }
const _hoisted_59 = { class: "col-lg-3 col-md-6 col-sm-6" }
const _hoisted_60 = { class: "col-lg-3 col-md-6 col-sm-6" }
const _hoisted_61 = { class: "col-lg-3 col-md-6 col-sm-6" }
const _hoisted_62 = { class: "row form-block travel-date" }
const _hoisted_63 = { class: "col-lg-3 col-md-6 col-sm-6" }
const _hoisted_64 = {
  key: 0,
  class: "col-lg-3 col-md-6 col-sm-6"
}
const _hoisted_65 = {
  key: 1,
  class: "col-lg-3 col-md-6 col-sm-6"
}
const _hoisted_66 = {
  key: 2,
  class: "col-lg-3 col-md-6 col-sm-6"
}
const _hoisted_67 = {
  key: 3,
  class: "col-lg-3 col-md-6 col-sm-6"
}
const _hoisted_68 = { class: "checkout-bottom_block" }
const _hoisted_69 = { class: "row" }
const _hoisted_70 = { class: "col-md-8 left-col" }
const _hoisted_71 = { class: "checkout-left-row-block" }
const _hoisted_72 = { class: "checkout-left-row" }
const _hoisted_73 = { class: "disclaimer-block" }
const _hoisted_74 = { class: "coupon-block-container" }
const _hoisted_75 = { class: "coupon-block" }
const _hoisted_76 = { class: "coupon-block-form" }
const _hoisted_77 = ["placeholder"]
const _hoisted_78 = ["value"]
const _hoisted_79 = ["value"]
const _hoisted_80 = {
  key: 0,
  class: "error"
}
const _hoisted_81 = { class: "col-md-4 right-col" }
const _hoisted_82 = { class: "summary" }
const _hoisted_83 = { class: "inner-title" }
const _hoisted_84 = { class: "mb-0" }
const _hoisted_85 = { class: "summary-block" }
const _hoisted_86 = { class: "summary-block__table" }
const _hoisted_87 = { class: "summary-block__table--item" }
const _hoisted_88 = { class: "left" }
const _hoisted_89 = { class: "right" }
const _hoisted_90 = { class: "left" }
const _hoisted_91 = { class: "right" }
const _hoisted_92 = {
  key: 1,
  class: "summary-block__table--item"
}
const _hoisted_93 = { class: "left" }
const _hoisted_94 = { class: "right" }
const _hoisted_95 = {
  key: 2,
  class: "summary-block__table--item"
}
const _hoisted_96 = { class: "left" }
const _hoisted_97 = {
  class: "right",
  dir: "ltr"
}
const _hoisted_98 = { class: "summary-block__table--item" }
const _hoisted_99 = { class: "left" }
const _hoisted_100 = { class: "right big-bold" }
const _hoisted_101 = {
  key: 0,
  class: "agree-terms-conditions"
}
const _hoisted_102 = { class: "agree-terms-conditions-checkbox" }
const _hoisted_103 = { for: "add-to-compare1" }
const _hoisted_104 = ["href"]
const _hoisted_105 = {
  key: 1,
  href: "javascript::void(0)",
  style: {"cursor":"default"}
}
const _hoisted_106 = {
  key: 1,
  class: "checkout-button"
}
const _hoisted_107 = ["disabled"]
const _hoisted_108 = { class: "text-center" }
const _hoisted_109 = { class: "vechile-list page-content" }
const _hoisted_110 = { class: "container" }
const _hoisted_111 = { class: "vechile-list-container main-container" }
const _hoisted_112 = { class: "vechile-list_row" }
const _hoisted_113 = { class: "row justify-content-center" }
const _hoisted_114 = { class: "col-md-6" }

import { computed, onMounted, ref } from "vue";
  import { store } from "@/store";
  import Quotes from "@/core/services/Quotes";
  import reviewService from "@/core/services/ReviewDetails";
  import personalService from "@/core/services/PersonalDetails";
  import Checkouts from "@/core/services/Checkouts";
  import { useReCaptcha } from "vue-recaptcha-v3";
  import Insurances from "@/core/services/Insurances";
  import Countries from "@/core/services/Countries";
  import { getTextWidth, setDateMdY } from '@/store/stateless/globalVar'
  import router from "@/router";
  import Coupons from "@/core/services/Coupons";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutDetails',
  setup(__props) {

/* eslint-disable */
  const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
  const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

  const isOrient = ref(process.env.VUE_APP_ORIENT_INSURANCE_ID);
  const isIsa = ref(process.env.VUE_APP_ISA_INSURANCE_ID);

  const readMore = ref(false);
  const captcha = useReCaptcha();
  const loading = ref(false)

  const coupon = ref();

  const customer = computed(() => {
    return store.state.personals.customer;
  });

  const quote: any = computed(() => {
    return store.state.review.review_details;
  });

  const totalPremium = ref(0);
  const isAccept = ref(true);
  const isSent = ref(false);

  const vatper = computed(() => {
    return store.state.quotes.vat;
  });

  const whatsappContent = computed(() => {
    return store.state.quotes.whatapp_template;
  });

  const language = computed(() => {
    return window.localStorage.getItem("language");
  });

  const agent = computed(() => {
    return router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
  });

  const vat = computed(() => {
    if (parseInt(vatper.value) > 0) {
      if(quote.value.invoice?.insurance_provider_id == isIsa.value) {
        return parseFloat(quote.value.invoice?.vat);
      } else {
        return parseFloat(quote.value.invoice?.total_price_without_vat) * (parseInt(vatper.value) / 100);
      }
    }
    return 0;
  });

  const calculateTotalPremium = () => {
    totalPremium.value = parseFloat(quote.value?.invoice?.total_price_vat) - parseFloat(discount.value);
      // parseFloat(quote.value?.invoice?.total_price_without_vat) + vat.value;
  };

  onMounted(async () => {
    window.localStorage.setItem("is_edit", "1");
    await Countries.getCountries();
    await Quotes.getVat();
    await Insurances.getInsuredTypes();

    window.localStorage.setItem("traveller_index", JSON.stringify(0));

    const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
    if (uuid) {
      const payload = {
        uuid: uuid,
        link: window.location.origin + window.location.pathname + "?lang=en&uuid=" + uuid,
        pagename: 'checkout-detail'
      };
      await reviewService.getDetailsCheckout(payload);
      await personalService.getDetails({ uuid:uuid });
    }

    calculateTotalPremium();
  });

  const sentLinkToCustomer = async (buttonType, pageName) => {
    if (buttonType == 'whatsapp') {
      const url = `https://api.whatsapp.com/send?phone=${customer.value?.complete_phone_number}&text=${encodeURIComponent(whatsappContent.value)}`
      window.open(url)
    }
    if (buttonType == 'email') {
      const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
      const agent = router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
      const payload = {
        uuid: uuid,
        pagename: pageName,
        agent_id:agent,
        link: window.location.origin + window.location.pathname + "?lang=en&uuid=" + uuid
      }
      await Quotes.sendEmail(payload);
      isSent.value = true;
      setTimeout(() => {
        isSent.value = false;
      }, 5000)
    }
  }

  const proceedClick = async () => {
    if(!couponSuccess.value) coupon.value = ''
    // load captcha
    await captcha?.recaptchaLoaded();
    // execute reCaptcha
    const token = await captcha?.executeRecaptcha("login");
    if (token) {
      loading.value = true
      const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
      const sessionId = window.localStorage.getItem('session_id');
      if (uuid) {
        const payload = {
          merchant_reference: quote.value?.invoice?.quote_ref_no,
          uuid: uuid,
          language: language.value,
          vat: vat.value,
          total_price_vat: parseFloat(totalPremium.value) + parseFloat(discount.value),
          url: process.env.VUE_APP_PAYMENT_GATEWAY_URL + `/` + language.value,
          session_id: sessionId ?? '',
          coupon: coupon.value,
        };
        console.log(payload)
        await Checkouts.postCheckout(payload);
        setTimeout(() => {
          loading.value = false
        }, 2500);
      }
    }
  };

  const addDash = (value) => {
    return value ? value : '-'
  }

  const couponError = computed(() => {
    return store.state.coupon.error;
  });
  const couponSuccess = computed(() => {
    return store.state.coupon.success;
  });
  // submit event
  const couponCode = async() => {
      await Coupons.postCoupon({
        promo_code: coupon.value,
        merchant_reference: quote.value?.invoice?.quote_ref_no,
        language:language.value,
        insurance_type:'travel'
    });
    calculateTotalPremium()
  };

  const couponRemove = () => {
    coupon.value = '';
    Coupons.removeCoupon();
    calculateTotalPremium()
  };

  const trimSpaces = ()=>{
    coupon.value = coupon.value.replace(/\s+/g, '');
  }

  const discount = computed(() => {
    let disc = 0;
    let coupon = store.state.coupon.success;
    if (coupon) {
      disc = coupon.data.discount_amount || 0;
    }
    return disc;
  });


return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_h_page_loader = _resolveComponent("h-page-loader")!

  return (quote.value && quote.value?.invoice)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("secure_checkout")), 1),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("qoute_reference")) + " ", 1),
                  _createElementVNode("strong", null, _toDisplayString(quote.value?.invoice?.quote_ref_no), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("img", {
                          src: `${domain.value}${url.value}/img/${quote.value?.invoice?.get_provider_detail?.image}`,
                          class: "img-fluid"
                        }, null, 8, _hoisted_12)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("h5", _hoisted_15, _toDisplayString(quote.value?.invoice?.insurance_plan_name) + " - " + _toDisplayString(quote.value?.invoice?.insurance_coverage_name) + " - " + _toDisplayString(quote.value?.invoice?.insurance_cover_for), 1)
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "icon" }, [
                              _createElementVNode("img", {
                                src: _imports_0,
                                class: "img-fluid"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, " (" + _toDisplayString(_ctx.$t("from")) + " " + _toDisplayString(_unref(setDateMdY)(quote.value.start_date)) + " " + _toDisplayString(_ctx.$t("to")) + " " + _toDisplayString(_unref(setDateMdY)(quote.value.end_date)) + ") ", 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "icon" }, [
                              _createElementVNode("img", {
                                src: _imports_1,
                                class: "img-fluid"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["text", {'wrap-content' : _unref(getTextWidth)(quote.value.destination_country) > 150 ? true : false }])
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("destination_label")) + " : ", 1),
                                _createVNode(_component_el_tooltip, {
                                  class: "box-item",
                                  effect: "light",
                                  disabled: _unref(getTextWidth)(quote.value.destination_country) > 150 ? false : true
                                }, {
                                  content: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_22, _toDisplayString(quote.value.destination_country), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(quote.value.destination_country), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ], 2)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quote.value?.invoice
                                ?.highlight, (highlight, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (highlight.section == 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                    _createElementVNode("p", null, [
                                      _createTextVNode(_toDisplayString(language.value === "ar"
                              ? highlight.label_ar
                              : highlight.label) + " ", 1),
                                      _createElementVNode("span", null, _toDisplayString(highlight && highlight.value ? highlight.value : '-'), 1)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t("total_premium")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_29, [
                              _createElementVNode("h4", _hoisted_30, _toDisplayString(_ctx.currency.format(quote.value.invoice?.total_price_without_vat)), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t("no_of_traveller_placeholder")) + " : " + _toDisplayString(quote.value.no_of_traveller), 1)
                          ]),
                          (
                                agent.value != '' &&
                                agent.value != 'undefined' &&
                                agent.value != undefined
                              )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "mb-0" }, "Share", -1)),
                                _createElementVNode("ul", _hoisted_34, [
                                  _createElementVNode("li", null, [
                                    _createElementVNode("div", _hoisted_35, [
                                      _createElementVNode("button", {
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => (sentLinkToCustomer('whatsapp', 'checkout-detail')))
                                      }, _cache[8] || (_cache[8] = [
                                        _createElementVNode("img", {
                                          src: _imports_2,
                                          alt: "whatsapp"
                                        }, null, -1)
                                      ]))
                                    ])
                                  ]),
                                  _createElementVNode("li", null, [
                                    _createElementVNode("div", {
                                      class: "icon",
                                      onClick: _cache[1] || (_cache[1] = ($event: any) => (sentLinkToCustomer('email', 'checkout-detail')))
                                    }, _cache[9] || (_cache[9] = [
                                      _createElementVNode("img", {
                                        src: _imports_3,
                                        alt: "email"
                                      }, null, -1)
                                    ]))
                                  ])
                                ]),
                                (isSent.value)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[10] || (_cache[10] = [
                                      _createElementVNode("div", { class: "toast-body" }, " Email sent successfully! ", -1)
                                    ])))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quote.value?.invoice?.highlight, (highlight, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (highlight.section == 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                              _createElementVNode("p", null, [
                                _createTextVNode(_toDisplayString(language.value === "ar"
                        ? highlight.label_ar
                        : highlight.label) + " ", 1),
                                _createElementVNode("span", null, _toDisplayString(highlight && highlight.value ? highlight.value : '-'), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    (
                      quote.value?.invoice?.highlight &&
                      quote.value?.invoice?.highlight.length
                    )
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["result-listing__item__block-bottom", readMore.value ? 'open' : '']),
                          style: _normalizeStyle(`display: ${readMore.value ? 'block' : 'none'}`)
                        }, [
                          _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, [
                                _createElementVNode("h4", _hoisted_45, _toDisplayString(_ctx.$t("full_summary")), 1)
                              ]),
                              _createVNode(_component_el_scrollbar, { height: "170px" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_46, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quote.value?.invoice
                                ?.highlight, (highlight, index) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                        (highlight.section == 3)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                              _createElementVNode("div", _hoisted_48, [
                                                _createElementVNode("ul", null, [
                                                  _createElementVNode("li", _hoisted_49, [
                                                    _createTextVNode(_toDisplayString(language.value === "ar"
                                      ? highlight.label_ar
                                      : highlight.label) + " ", 1),
                                                    _createElementVNode("span", _hoisted_50, _toDisplayString(language.value === "ar"
                                        ? addDash(highlight.value_ar)
                                        : addDash(highlight.value)), 1)
                                                  ])
                                                ])
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 128))
                                  ])
                                ]),
                                _: 1
                              })
                            ])
                          ])
                        ], 6))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (
                  quote.value?.invoice?.highlight && quote.value?.invoice?.highlight.length
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                      _createElementVNode("button", {
                        class: _normalizeClass(["btn", readMore.value ? 'active' : '']),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (readMore.value = !readMore.value))
                      }, [
                        _createElementVNode("i", null, _toDisplayString(!readMore.value ? _ctx.$t("show_full_summary") : _ctx.$t("hide_full_summary")), 1),
                        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "icon-dropdown-down" }, null, -1))
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("div", _hoisted_53, [
                    _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "mb-0" }, "Traveller details", -1)),
                    (quote.value?.travellers_info)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quote.value?.travellers_info, (traveller, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "item",
                              key: index
                            }, [
                              _createElementVNode("h4", _hoisted_55, _toDisplayString(_ctx.$t("traveller_seq", { count: index + 1 })), 1),
                              traveller
                                ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                                    _createElementVNode("div", _hoisted_57, [
                                      _createElementVNode("div", _hoisted_58, [
                                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("title")), 1),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.dropDown.getPrefex(traveller.type)), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_59, [
                                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("first_name")), 1),
                                        _createElementVNode("p", null, _toDisplayString(traveller.first_name), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_60, [
                                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("middle_name")), 1),
                                        _createElementVNode("p", null, _toDisplayString(traveller.middle_name ?? '-'), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_61, [
                                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("last_name")), 1),
                                        _createElementVNode("p", null, _toDisplayString(traveller.last_name), 1)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_62, [
                                      _createElementVNode("div", _hoisted_63, [
                                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("DOB")), 1),
                                        _createElementVNode("p", null, _toDisplayString(traveller.date_of_birth), 1)
                                      ]),
                                      (
                              quote.value?.invoice &&
                              [parseInt(isIsa.value)].includes(
                                parseInt(quote.value?.invoice?.insurance_provider_id)
                              )
                            )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                                            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("gender")), 1),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.dropDown.getGender(traveller.gender_id)), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (
                              quote.value?.invoice &&
                              [parseInt(isIsa.value)].includes(
                                parseInt(quote.value?.invoice?.insurance_provider_id)
                              )
                            )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                                            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("country_of_residence")), 1),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.dropDown.getNationality(traveller.country_id)), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (
                              quote.value?.invoice &&
                              [parseInt(isIsa.value)].includes(
                                parseInt(quote.value?.invoice?.insurance_provider_id)
                              )
                            )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                                            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("nationality")), 1),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.dropDown.getNationality(traveller.nationality_id)), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (
                              quote.value?.invoice &&
                              [parseInt(isOrient.value),parseInt(isIsa.value)].includes(
                                parseInt(quote.value?.invoice?.insurance_provider_id)
                              )
                            )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                                            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("passport")), 1),
                                            _createElementVNode("p", null, _toDisplayString(traveller.passport), 1)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_68, [
                    _createElementVNode("div", _hoisted_69, [
                      _createElementVNode("div", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, [
                          _createElementVNode("div", _hoisted_72, [
                            _createElementVNode("div", _hoisted_73, [
                              _createElementVNode("div", _hoisted_74, [
                                _createElementVNode("div", _hoisted_75, [
                                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('coupons')), 1),
                                  _createElementVNode("div", _hoisted_76, [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      placeholder: _ctx.$t('coupon_ph'),
                                      class: "inputfield",
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((coupon).value = $event)),
                                      onInput: trimSpaces
                                    }, null, 40, _hoisted_77), [
                                      [_vModelText, coupon.value]
                                    ]),
                                    (!couponSuccess.value)
                                      ? (_openBlock(), _createElementBlock("input", {
                                          key: 0,
                                          type: "submit",
                                          onClick: _withModifiers(couponCode, ["prevent"]),
                                          value: _ctx.$t('apply'),
                                          class: "btn-outlined-blue no-border"
                                        }, null, 8, _hoisted_78))
                                      : (_openBlock(), _createElementBlock("input", {
                                          key: 1,
                                          type: "submit",
                                          onClick: _withModifiers(couponRemove, ["prevent"]),
                                          value: _ctx.$t('remove'),
                                          class: "btn-outlined-red no-border ash-text-color"
                                        }, null, 8, _hoisted_79))
                                  ]),
                                  (couponError.value)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_80, _toDisplayString(couponError.value), 1))
                                    : _createCommentVNode("", true),
                                  _withDirectives(_createElementVNode("span", { class: "success" }, _toDisplayString(_ctx.$t('coupon_applied')), 513), [
                                    [_vShow, couponSuccess.value]
                                  ])
                                ])
                              ]),
                              _createElementVNode("h3", null, _toDisplayString(_ctx.$t("disclaimer")), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t("disclaimer_details")), 1)
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_81, [
                        _createElementVNode("div", _hoisted_82, [
                          _createElementVNode("div", _hoisted_83, [
                            _createElementVNode("h5", _hoisted_84, _toDisplayString(_ctx.$t("summary")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_85, [
                            _createElementVNode("div", _hoisted_86, [
                              _createElementVNode("div", _hoisted_87, [
                                _createElementVNode("div", _hoisted_88, _toDisplayString(_ctx.$t("premium")), 1),
                                _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.currency.format(
                                quote.value?.invoice?.insurance_plan_price,
                                2
                                )), 1)
                              ]),
                              (quote.value?.invoice?.policy_addons && quote.value?.invoice?.policy_addons.length > 0)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(quote.value?.invoice?.policy_addons, (addon, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "summary-block__table--item",
                                      key: index
                                    }, [
                                      _createElementVNode("div", _hoisted_90, _toDisplayString(addon.addon_name), 1),
                                      _createElementVNode("div", _hoisted_91, _toDisplayString(_ctx.currency.format(addon.addon_price)), 1)
                                    ]))
                                  }), 128))
                                : _createCommentVNode("", true),
                              (vat.value > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
                                    _createElementVNode("div", _hoisted_93, _toDisplayString(_ctx.$t("vat_t", { vat: vatper.value })), 1),
                                    _createElementVNode("div", _hoisted_94, _toDisplayString(_ctx.currency.format(vat.value || 0, 2)), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (discount.value > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                                    _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.$t('discount')), 1),
                                    _createElementVNode("div", _hoisted_97, _toDisplayString(_ctx.currency.format(discount.value || 0, 2)), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_98, [
                                _createElementVNode("div", _hoisted_99, _toDisplayString(_ctx.$t("payable_amount")), 1),
                                _createElementVNode("div", _hoisted_100, _toDisplayString(_ctx.currency.format(totalPremium.value, 2)), 1)
                              ])
                            ]),
                            (
                              agent.value == '' || agent.value == null || agent.value == undefined
                            )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                                  _createElementVNode("div", _hoisted_102, [
                                    _withDirectives(_createElementVNode("input", {
                                      class: "styled-checkbox",
                                      id: "add-to-compare1",
                                      type: "checkbox",
                                      value: "value1",
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isAccept).value = $event))
                                    }, null, 512), [
                                      [_vModelCheckbox, isAccept.value]
                                    ]),
                                    _createElementVNode("label", _hoisted_103, [
                                      _createElementVNode("span", null, [
                                        _createTextVNode(_toDisplayString(_ctx.$t("agree")) + " ", 1),
                                        (quote.value && quote.value.plan_documents && quote.value.plan_documents.file_url)
                                          ? (_openBlock(), _createElementBlock("a", {
                                              key: 0,
                                              href: `${quote.value.plan_documents.file_url}`,
                                              target: "_blank"
                                            }, _toDisplayString(_ctx.$t('tac')), 9, _hoisted_104))
                                          : (_openBlock(), _createElementBlock("a", _hoisted_105, _toDisplayString(_ctx.$t("tac")), 1))
                                      ])
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (
                              agent.value == '' || agent.value == null || agent.value == undefined
                            )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_106, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    disabled: !isAccept.value || loading.value,
                                    class: "btn btn-checkout",
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (proceedClick()))
                                  }, [
                                    _createElementVNode("span", _hoisted_108, _toDisplayString(_ctx.$t("proceed")), 1)
                                  ], 8, _hoisted_107)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("section", _hoisted_109, [
          _createElementVNode("div", _hoisted_110, [
            _createElementVNode("div", _hoisted_111, [
              _createElementVNode("div", _hoisted_112, [
                _createElementVNode("div", _hoisted_113, [
                  _createElementVNode("div", _hoisted_114, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('wait')), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_h_page_loader)
      ], 64))
}
}

})