import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wizard-navigation_item--title" }
const _hoisted_2 = { class: "wizard-navigation_item--title" }
const _hoisted_3 = { class: "wizard-navigation_item--title" }
const _hoisted_4 = { class: "wizard-navigation_item--title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["wizard-navigation_item position-relative", {active: _ctx.$route.name === 'TravellerDetails'}]),
      style: {"cursor":"pointer"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.header.go(1)))
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "wizard-navigation_item--number" }, "1", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(_ctx.$t("travel")) + " ", 1),
          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("details")), 1)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["wizard-navigation_item position-relative", {active: _ctx.$route.name === 'CompareQuotes'}]),
      style: {"cursor":"pointer"},
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.header.go(2)))
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "wizard-navigation_item--number" }, "2", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(_ctx.$t("compare")) + " ", 1),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("quotes")), 1)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["wizard-navigation_item position-relative", {active: _ctx.$route.name === 'ReviewDetails'}]),
      style: {"cursor":"pointer"},
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.header.go(4)))
    }, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "wizard-navigation_item--number" }, "3", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(_ctx.$t("review")) + " ", 1),
          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("details")), 1)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["wizard-navigation_item position-relative", {active: _ctx.$route.name === 'CheckoutDetails'}]),
      style: {"cursor":"pointer"},
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.header.go(5)))
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "wizard-navigation_item--number" }, [
        _createElementVNode("span", { class: "icon-Tickmark" })
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(_ctx.$t("purchase")) + " ", 1),
          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("insurance")), 1)
        ])
      ])
    ], 2)
  ], 64))
}