import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-block" }
const _hoisted_2 = { class: "label-block" }
const _hoisted_3 = { class: "label-block_label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "label-block_question position-relative"
}
const _hoisted_6 = { class: "tooltip-content" }
const _hoisted_7 = { class: "input-block" }
const _hoisted_8 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.isTooltip)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "light"
            }, {
              content: _withCtx(() => [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.tooltipMessage), 1)
              ]),
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("a", {
                  class: "popper",
                  "data-bs-trigger": "hover",
                  placement: "right",
                  "data-bs-toggle": "popover"
                }, [
                  _createElementVNode("div", { class: "icon" }, [
                    _createElementVNode("span", { class: "icon-question-icon" })
                  ])
                ], -1))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.nationality_ids)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: `#${_ctx.nationality_ids}`
              }, [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.nationality_search,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nationality_search) = $event)),
                  placeholder: "Search ..",
                  ref: "nationality_search_ref",
                  style: {"position":"absolute","top":"0px"}
                }, null, 8, ["modelValue"])
              ], 8, ["to"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.colSize)
        }, [
          _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                "model-value": _ctx.modelValue,
                filterable: !_ctx.isMobile,
                placeholder: _ctx.placeHolder,
                class: "select-input",
                onChange: _ctx.updateValue,
                ref: "nationality",
                "popper-class": {'nationality_teleport': _ctx.isMobile}
              }, {
                default: _withCtx(() => [
                  (_ctx.isMobile)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filtered_nationality, (item, index) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          class: "vehicle",
                          key: index,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    : _createCommentVNode("", true),
                  (!_ctx.isMobile)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.countries, (item, index) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          class: "vehicle",
                          key: index,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["model-value", "filterable", "placeholder", "onChange", "popper-class"])
            ]),
            _: 1
          }, 8, ["prop"])
        ], 2)
      ])
    ])
  ]))
}