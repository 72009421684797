<template>
  <div class="wizard-section d-lg-none bg-white">
    <div
      class="wizard-navigation d-flex health-wizard justify-content-center wizard-navigation-health"
    >
    <h-no-quotes v-if="isMobile" />
    <h-header-default v-else />
  </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue";

import { store } from "@/store";
import Language from "@/core/services/Language";

export default defineComponent({
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", window.localStorage.getItem("language"));
    html.setAttribute(
      "dir",
      window.localStorage.getItem("language") == "ar" ? "rtl" : "ltr"
    );
  },

  setup() {
    const language = computed(() => {
      return store.state.lang.lang;
    });

    const changeLanguage = (e) => {
      Language.setLanguage(e);
      window.location.reload();
    };

    const homePage =
      process.env.VUE_APP_PAYMENT_GATEWAY_URL + `/` + language.value;

    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const withoutCompareQuotes = computed(() => {
      return store.getters.getQuotesFoundIssue;
    });

    const notValidAges = computed(() => {
      return store.getters.getNotValidAge;
    });

    const notValidDeclaration = computed(() => {
      return store.getters.getNotValidDeclaration(
        store.state.personals.customer.insured_type_id
      );
    });

    return {
      notValidAges,
      notValidDeclaration,
      withoutCompareQuotes,
      customer,
      homePage,
      language,
      changeLanguage,
    };
  },
});
</script>
