
import { computed, defineComponent, onMounted, ref } from "vue";
import { store } from "@/store";
import router from '@/router';
import personalService from "@/core/services/PersonalDetails";

export default defineComponent({
  /* eslint-disable */
  setup(_) {

    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const traveler = ref()

    onMounted(async () => {
      
      const agentId = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";
      if(agentId == '') {
        window.localStorage.removeItem('agent_id')
      }
      customer.value.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : '';
      customer.value.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : '';
      customer.value.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : '';
      customer.value.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : '';
      const session_id:any = router.currentRoute.value.query ? router.currentRoute.value.query.sid?.toString() : '';
      customer.value.agent_id = agentId ? parseInt(agentId) : 0;
      let uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;  
      if(session_id) window.localStorage.setItem('session_id', session_id)
      if(!uuid) uuid = window.localStorage.getItem('uuid')     

      if(uuid) {
        const payload = {
          uuid:uuid
        }
        await personalService.getDetails(payload);
        // customer.value.name = data.customer.name;
        // customer.value.phone_number = data.customer.phone_number_without_code;
        // customer.value.phone_country_code = data.customer.phone_country_code;
      } else {
        const name:any = router.currentRoute.value.query ? router.currentRoute.value.query.name?.toString() : '';
        const email:any = router.currentRoute.value.query ? router.currentRoute.value.query.email?.toString() : '';
        const phone_number:any = router.currentRoute.value.query ? router.currentRoute.value.query.pn?.toString() : '';
        const session_id:any = router.currentRoute.value.query ? router.currentRoute.value.query.sid?.toString() : '';
        const country_code:any = router.currentRoute.value.query && router.currentRoute.value.query.country_code ? router.currentRoute.value.query.country_code?.toString().replace('?', '') : '974';
        
        if(name) customer.value.name = name
        if(email) customer.value.email = email
        if(session_id) customer.value.session_id = session_id
        if(phone_number) customer.value.phone_number = phone_number
        if(country_code) customer.value.phone_country_code = `+${country_code?.replace(' ', '')}`

        personalService.setCustomer(customer.value)
      }
    })

    return {
      traveler,
      customer,
    };
  },
});
