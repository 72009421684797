import { Countries, Country_code } from '@/core/types';


export type State = {
  countries: Array<Countries>;
  uae_id: number | null;
  countryCode: Array<Country_code>;
}

export const state: State = {
  countries: [] as Array<Countries>,
  uae_id:  188,
  countryCode: [] as Array<Country_code>,
};