import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList } from "vue"
import _imports_0 from '@/assets/images/icons/whatsapp-checkout.svg'
import _imports_1 from '@/assets/images/icons/mail-checkout.svg'


const _hoisted_1 = { class: "vechile-list page-content" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "vechile-list-container main-container" }
const _hoisted_4 = { class: "vechile-list_row" }
const _hoisted_5 = { class: "row justify-content-center" }
const _hoisted_6 = {
  class: "col-md-6",
  style: {"height":"55vh !important"}
}
const _hoisted_7 = {
  key: 0,
  class: "vechile-list page-content"
}
const _hoisted_8 = { class: "container" }
const _hoisted_9 = {
  key: 0,
  role: "alert",
  "aria-live": "assertive",
  "aria-atomic": "true",
  class: "toast custom-toast success-toast show",
  "data-bs-autohide": "false"
}
const _hoisted_10 = { class: "vechile-list-container main-container" }
const _hoisted_11 = { class: "vechile-list_row health-insurance-compare compare-share" }
const _hoisted_12 = { class: "we-found text-center" }
const _hoisted_13 = { class: "row justify-content-center" }
const _hoisted_14 = { class: "col-md-12" }
const _hoisted_15 = { class: "d-flex align-items-center justify-content-center m-0" }
const _hoisted_16 = { class: "tooltip-content" }
const _hoisted_17 = {
  key: 1,
  class: "mx-2"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "page-title-block result-listing-title inner" }
const _hoisted_20 = { class: "row align-items-center gx-lg-5" }
const _hoisted_21 = { class: "col-lg-4 col-md-4 col-sm-12" }
const _hoisted_22 = { class: "page-title m-0" }
const _hoisted_23 = { class: "mb-0" }
const _hoisted_24 = { class: "col-lg-4 col-md-4 col-sm-12" }
const _hoisted_25 = {
  key: 0,
  class: "share-icons mt-0"
}
const _hoisted_26 = { class: "p-0 d-flex align-items-center justify-content-center" }
const _hoisted_27 = { class: "icon" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "listing-ul" }
const _hoisted_30 = { class: "result-listing" }
const _hoisted_31 = { class: "result-listing-full-block" }
const _hoisted_32 = { class: "insurance-company__body showall" }
const _hoisted_33 = {
  key: 1,
  class: "vechile-list page-content"
}
const _hoisted_34 = { class: "container" }
const _hoisted_35 = { class: "vechile-list-container main-container" }
const _hoisted_36 = { class: "vechile-list_row" }
const _hoisted_37 = { class: "result-listing-full-block" }
const _hoisted_38 = { class: "text-center no-result-block" }

import { store } from "@/store";
    import { ref, onMounted, computed, watchEffect } from 'vue'
    import Quotes from '@/core/services/Quotes';
    
    import { setDateMdY } from '@/store/stateless/globalVar'
    import router from "@/router";
    import moment from "moment";

    
export default /*@__PURE__*/_defineComponent({
  __name: 'CompareQuotes',
  setup(__props) {

_useCssVars(_ctx => ({
  "732e4a18": (sizeWrapSubtitleNind.value)
}))

/* eslint-disable */
    const insurance_type = ref('all');
    const count = ref(0)
    const drawer = ref(false)
    const loading = ref(true)
    const isSent = ref(false)

    const leadInfo = computed(() => {
        return store.state.quotes.lead
    });
    const quoteList = computed(() => {
        return store.state.quotes.quotes
    });
    const customerInfo = computed(() => {
        return store.state.quotes.informations
    });
    const whatsappContent = computed(() => {
        return store.state.quotes.whatapp_template
    });
    const selectedBenefits = computed(() => {
        return store.state.quotes.benefitsArray
    });
    const drawerQuote = (() => {
        drawer.value = false;
        if (selectedBenefits.value.length > 0) {
            drawer.value = true;
        }
    });
    watchEffect(async () => {
        if (store.state.quotes.quote_benefit_id) {
            drawerQuote()
        }
    });
    const compareList = computed(() => {
        return store.state.quotes.compare_list
    });
    const agent = computed(() => {
        return router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
    });

    const sentLinkToCustomer = async (buttonType, pageName) => {
        if (buttonType == 'whatsapp') {
            const url = `https://api.whatsapp.com/send?phone=${customerInfo.value?.complete_phone_number}&text=${encodeURIComponent(whatsappContent.value)}`
            window.open(url)
        }
        if (buttonType == 'email') {
            const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null; 
            const agent = router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
            const payload = {
                uuid: uuid,
                pagename: pageName,
                agent_id:agent,
                link: window.location.origin + window.location.pathname + "?lang=en&uuid=" + uuid
            }
            await Quotes.sendEmail(payload);
            isSent.value = true;
            setTimeout(() => {
                isSent.value = false;
            }, 5000)
        }
    }

    const changeInsuranceType = async (value) => {
        insurance_type.value = value
        const uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
        if(uuid) {
            const payload = {
                uuid: uuid,
                link: window.location.origin + window.location.pathname + "?lang=en&uuid=" + uuid
            }
            await Quotes.getQuotes(payload);
            count.value = quoteList.value.length;
            loading.value = false;
        } else {
            const appendPath = router.currentRoute.value.query ?? '';
            const routeName = 'TravellerDetails'
            const route = router.resolve({ name: routeName, query: appendPath });
            
            window.location.href = route.href;
        }
    };

    onMounted(async () => {
        changeInsuranceType(insurance_type.value)
        compareList.value.splice(0, compareList.value.length)
        if(compareList.value.length) {
            abc()
        }
    });


    // const compareList = ref<any[]>([])

    const addToCompare = (e: any) => {
        const index = compareList.value.findIndex((item) => item == e);
        if (index == -1) {
            if (compareList.value.length < 3)
                compareList.value.push(e);
        } else {
            compareList.value.splice(index, 1);
        }
    };

    const sizeWrapSubtitle = computed(() => {
        return 200
    })

    const sizeWrapSubtitleNind = computed(() => {
        return `${sizeWrapSubtitle.value}px`
    })

    function getTextWidth(text, font) {
        const canvas = document.createElement("canvas");
        if(canvas) {
            const context = canvas.getContext("2d");

            context.font = font || getComputedStyle(document.body).font;

            return context.measureText(text).width;
        }
    }

    // function setDateOnly(e) {
    //     const MF = moment(e, 'DD/MM/YYYY').isValid()
    //     if(!MF) return moment(e).format("DD/MM/YYYY");
    //     else return e
    // }

function abc(){
    
    let ulElement = document.querySelector(".travel-quotes li");
    console.log(ulElement);
    // ulElement.forEach(myFunction);

}

 
// function myFunction(item, index) {
//   text += index + ": " + item + "<br>"; 
// }

return (_ctx: any,_cache: any) => {
  const _component_h_page_loader = _resolveComponent("h-page-loader")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _component_h_quote_details = _resolveComponent("h-quote-details")!
  const _component_h_no_quotes = _resolveComponent("h-no-quotes")!
  const _component_h_compare_list = _resolveComponent("h-compare-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loading.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('searching_for_insurance')), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createVNode(_component_h_page_loader)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (quoteList.value?.length > 0)
            ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (isSent.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[3] || (_cache[3] = [
                        _createElementVNode("div", { class: "toast-body" }, " Email sent successfully! ", -1)
                      ])))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("p", _hoisted_15, [
                              _createTextVNode(_toDisplayString(_ctx.$t('quotes_count', { count: quoteList.value?.length  })) + " ", 1),
                              (getTextWidth(leadInfo.value?.destination_country) > sizeWrapSubtitle.value)
                                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                    key: 0,
                                    class: "box-item",
                                    effect: "light",
                                    disabled: getTextWidth(leadInfo.value?.destination_country) > sizeWrapSubtitle.value ? false : true
                                  }, {
                                    content: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_16, " " + _toDisplayString(leadInfo.value?.destination_country), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["popper", { 'wrap-content': getTextWidth(leadInfo.value?.destination_country) > sizeWrapSubtitle.value ? true : false }]),
                                        "data-bs-trigger": "hover",
                                        placement: "right",
                                        "data-bs-toggle": "popover"
                                      }, "  " + _toDisplayString(leadInfo.value?.destination_country), 3)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(leadInfo.value?.destination_country), 1))
                            ]),
                            _createElementVNode("p", {
                              innerHTML: _ctx.$t('quotes_count_dates', { startdt: _unref(setDateMdY)(leadInfo.value?.start_date), enddt: _unref(setDateMdY)(leadInfo.value?.end_date) })
                            }, null, 8, _hoisted_18)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("h2", _hoisted_23, _toDisplayString(_ctx.$t('compare')), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('price_vat', { vat: '5' })), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            (
                                                agent.value != '' &&
                                                agent.value != 'undefined' &&
                                                agent.value != undefined
                                            )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                  _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "mb-0 text-center" }, "Share", -1)),
                                  _createElementVNode("ul", _hoisted_26, [
                                    _createElementVNode("li", null, [
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("button", {
                                          onClick: _cache[0] || (_cache[0] = ($event: any) => (sentLinkToCustomer('whatsapp', 'compare-quote')))
                                        }, _cache[4] || (_cache[4] = [
                                          _createElementVNode("img", {
                                            src: _imports_0,
                                            alt: "whatsapp"
                                          }, null, -1)
                                        ]))
                                      ])
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("div", {
                                        class: "icon",
                                        onClick: _cache[1] || (_cache[1] = ($event: any) => (sentLinkToCustomer('email', 'compare-quote')))
                                      }, _cache[5] || (_cache[5] = [
                                        _createElementVNode("img", {
                                          src: _imports_1,
                                          alt: "email"
                                        }, null, -1)
                                      ]))
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      (quoteList.value?.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            (selectedBenefits.value?.length > 0)
                              ? (_openBlock(), _createBlock(_component_el_drawer, {
                                  key: 0,
                                  modelValue: drawer.value,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((drawer).value = $event)),
                                  title: _ctx.$t('all_plan_benefits'),
                                  size: "60%"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_29, [
                                      _createElementVNode("ul", null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedBenefits.value, (benefit, indexB) => {
                                          return (_openBlock(), _createElementBlock("li", { key: indexB }, [
                                            _createTextVNode(_toDisplayString(benefit.label) + " ", 1),
                                            _createElementVNode("span", null, _toDisplayString(benefit.value_to_display), 1)
                                          ]))
                                        }), 128))
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "title"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_30, [
                              _createElementVNode("div", _hoisted_31, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quoteList.value, (quote, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "result-listing__insurance-company"
                                  }, [
                                    _createElementVNode("div", _hoisted_32, [
                                      _createVNode(_component_h_quote_details, {
                                        quote: quote,
                                        drawer: false,
                                        position: index,
                                        expand: true,
                                        onAddToCompare: addToCompare
                                      }, null, 8, ["quote", "position"])
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (quoteList.value && quoteList.value.length == 0)
                        ? (_openBlock(), _createElementBlock("section", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("div", _hoisted_35, [
                                _createElementVNode("div", _hoisted_36, [
                                  _createElementVNode("div", _hoisted_37, [
                                    _createElementVNode("div", _hoisted_38, [
                                      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('no_quotes_found')), 1),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('no_quotes_point_one')), 1)
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createBlock(_component_h_no_quotes, { key: 1 }))
        ], 64)),
    _createVNode(_component_h_compare_list, {
      isOpen: compareList.value.length > 1
    }, null, 8, ["isOpen"])
  ], 64))
}
}

})