import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-block confirm-details" }
const _hoisted_2 = { class: "label-block mb-0" }
const _hoisted_3 = { class: "d-flex w-100 align-items-center switch-container switch-container-v2 flex-nowrap flex-row" }
const _hoisted_4 = { class: "label-block_label mb-0" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = { class: "switch-toggle switch-toggle-v2 mt-0" }
const _hoisted_7 = {
  for: "inOut",
  class: "mb-0"
}
const _hoisted_8 = ["data-checked", "data-unchecked"]
const _hoisted_9 = { class: "label-block_label mb-0 pe-0" }
const _hoisted_10 = { class: "mb-0" }
const _hoisted_11 = { key: 0 }

import { store } from "@/store";
import { computed, defineComponent, toRef, ref, watch } from "vue";


  
export default /*@__PURE__*/_defineComponent({
  __name: 'InputInboundOutbound',
  props: {
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    labela: {
      type: String,
      required: true,
      default: "",
    },
    modelValue: {
      type: Boolean,
      required: true,
    }
  },
  emits: [ 'setValue', 'update:modelValue' ],
  setup(__props, { emit: __emit }) {

/* eslint-disable */
const props = __props

  const emit = __emit

    const myValue = toRef(props, "modelValue");

    const selectedValue = ref(false)

    watch(() => props.modelValue, newValue => {
      selectedValue.value = !newValue
    })

    const changeValue = () => {
      // emit("update:modelValue", selectedValue.value)
      emit('setValue')
    }


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(__props.label), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            id: "inOut",
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event)),
            onChange: changeValue
          }, null, 544), [
            [_vModelCheckbox, selectedValue.value]
          ]),
          _createElementVNode("label", _hoisted_7, [
            _createElementVNode("div", {
              class: "switch-toggle__switch",
              "data-checked": _ctx.$t('into'),
              "data-unchecked": _ctx.$t('outof')
            }, null, 8, _hoisted_8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, [
            _createTextVNode(_toDisplayString(__props.labela) + " ", 1),
            (__props.isRequired)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "*"))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}
}

})