
/* eslint-disable */
import { defineComponent, reactive, ref, computed, watch, getCurrentInstance } from "vue";
import Validations from "@/core/validations";
import { FormInstance } from "element-plus";
import { Travel, Traveller } from "@/core/types";
import { loading, svgLoading, focus, dates as dDate, trip_dates_filter } from "@/store/stateless"
import moment from "moment";

export default defineComponent({
  name: 'reviewMemberFormComponent',
  props: {
    item: {
      type: Object,
      required: true,
      default: {} as Traveller,
    },
    counting: {
      type: Number,
      required: true,
      default: 1
    },
    insurance: {
      type: Array,
      required: true,
      default: []
    },
    review_info: {
      type: Object,
      required: true,
      default: {} as Travel,
    },
  },

  setup(_) {
    const formReviewMember = ref<FormInstance>();
    
    const language = computed(() => {
      return window.localStorage.getItem('language');
    })

    const isOrient = ref(process.env.VUE_APP_ORIENT_INSURANCE_ID);
    const isIsa = ref(process.env.VUE_APP_ISA_INSURANCE_ID);

    const defaultDob = computed(() => {
      return moment().subtract(30,'y').format('YYYY-MM-DD')
    })

    const previousTraveller = (count) => {
        console.log(count);
        loading.value = true
       setTimeout(() => {
          window.localStorage.setItem('traveller_index', JSON.stringify(count - 1))
          _.review_info.travellers_info[count].is_editable = false;
          if(_.review_info.travellers_info[count - 1] != undefined) {
              _.review_info.travellers_info[count - 1].is_editable = true;
          }
          loading.value = false
          scrollTo({ top: 120 })
       }, 500);
        return '';
    }

    const vv_type = (rule: any, value: any, callback: any) => {
        if (
        !value
        )
        callback(new Error(
            language.value === "ar" ? "الرجاء تحديد النوع" : "Please select type"
        ))
        else callback();
    };

    // const vv_passport = (rule: any, value: any, callback: any) => {
    //     if (
    //     // _.review_info.invoice?.insurance_provider_id == parseInt(isOrient.value) &&
    //     !value
    //     )
    //     callback(new Error(language.value === 'ar' ? 'الرجاء إدخال رقم جواز السفر' : `Please enter passport number`));
    //     else callback();
    // };

    const vv_gender = (rule: any, value: any, callback: any) => {
        if (_.review_info.invoice?.insurance_provider_id == parseInt(isIsa.value) && !value)  callback( new Error( language.value === "ar" ? "الرجاء تحديد الجنس" : "Please select gender" ) );
        else callback();
    };

    const vv_country_of_residence = (rule: any, value: any, callback: any) => {
        if (_.review_info.invoice?.insurance_provider_id == parseInt(isIsa.value) && !value) callback(new Error(language.value === 'ar' ? 'الرجاء اختيار بلد الإقامة' :  `Please select country of residence`));
        else callback();
    };

    const vv_nationality_id = (rule: any, value: any, callback: any) => {
        if (_.review_info.invoice?.insurance_provider_id == parseInt(isIsa.value) && !value) callback(new Error(language.value === 'ar' ? 'الرجاء اختيار بلد الإقامة' :  `Please select nationality`));
        else callback();
    };
  
    const rules = reactive({
        type: [{ validator: vv_type, trigger: ["blur", "change"] }],
        first_name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
        last_name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
        dob: [{ validator: Validations.dob, trigger: ["blur", "change"] }],
        passport: [{ validator: Validations.passportNumber, trigger: ["blur", "change"] }],
        gender_id: [{ validator: vv_gender, trigger: ["blur", "change"] }],
        nationality_id: [{ validator: vv_nationality_id, trigger: ["blur", "change"] }],
        country_id: [
        { validator: vv_country_of_residence, trigger: ["blur", "change"] },
        ],
    });

  const inst = getCurrentInstance()
  watch(() => focus.review, (newValue, oldValue) => {
    if(newValue != oldValue) {
      const toFocus:any = inst?.refs[newValue]
      if(toFocus != undefined) toFocus.focus()
    }
  })

    return {
        svgLoading,
        loading,
        focus,
        dDate,
        rules,
        isIsa,
        isOrient,
        defaultDob,
        language,
        previousTraveller,
        formReviewMember,
        trip_dates_filter
    }
  }
});
