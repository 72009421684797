/* eslint-disable */
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export type Getters = {
  getSuccess(state: State): any | null;
  getError(state: State): any | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getSuccess: (state) => state.success,
  getError: (state) => state.error,
};
