import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/icons/calender.png'
import _imports_1 from '@/assets/images/icons/travel-destination.svg'


const _hoisted_1 = {
  key: 0,
  class: "checkout page-content"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "checkout-container main-container" }
const _hoisted_4 = { class: "checkout_row" }
const _hoisted_5 = { class: "page-title plain" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "checkout-block travel-checkout review-page-block" }
const _hoisted_8 = { class: "checkout-top" }
const _hoisted_9 = { class: "checkout-top__block" }
const _hoisted_10 = { class: "result-listing__item__block_top_row row align-items-center" }
const _hoisted_11 = { class: "partner-logo col-lg-3 col-md-12" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "partner-content col-lg-7 col-md-12" }
const _hoisted_14 = { class: "partner-content_title" }
const _hoisted_15 = { class: "mb-0" }
const _hoisted_16 = { class: "partner-content_bottom d-flex mb-4 row flex-nowrap policy-details-checkout" }
const _hoisted_17 = { class: "partner-content_bottom_item col-md-6 flex-nowrap me-0" }
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { class: "text mw-100" }
const _hoisted_20 = { class: "partner-content_bottom_item col-md-6 flex-nowrap me-0" }
const _hoisted_21 = { class: "title align-items-start" }
const _hoisted_22 = { class: "tooltip-content" }
const _hoisted_23 = {
  key: 0,
  class: "partner-content_bottom_item cover-cost-block row flex-wrap"
}
const _hoisted_24 = {
  key: 0,
  class: "col-lg-4 col-md-6 me-0"
}
const _hoisted_25 = { class: "right-buy-now text-center h-100 col-lg-2 col-md-12 pt-0 border-0 traveller_right_block" }
const _hoisted_26 = { class: "right-buy-now-top" }
const _hoisted_27 = { class: "premium_block" }
const _hoisted_28 = { class: "premium-title" }
const _hoisted_29 = { class: "price-title" }
const _hoisted_30 = { class: "mb-0" }
const _hoisted_31 = { class: "traveller_block" }
const _hoisted_32 = { class: "no_of_traveller" }
const _hoisted_33 = { class: "checkout-middle-footer review-details-form" }
const _hoisted_34 = { class: "vechile-list page-content" }
const _hoisted_35 = { class: "container" }
const _hoisted_36 = { class: "vechile-list-container main-container" }
const _hoisted_37 = { class: "vechile-list_row" }
const _hoisted_38 = { class: "row justify-content-center" }
const _hoisted_39 = { class: "col-md-6" }

import { computed, onMounted, ref } from "vue";
  import { store } from "@/store";
  import Quotes from "@/core/services/Quotes";
  import Insurances from "@/core/services/Insurances";
  import reviewService from "@/core/services/ReviewDetails";
  import Countries from "@/core/services/Countries";
  import { getTextWidth, setDateMdY } from '@/store/stateless/globalVar'
  import router from "@/router";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ReviewDetails',
  setup(__props) {

/* eslint-disable */
  const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
  const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

  const language = computed(() => {
    return window.localStorage.getItem("language");
  });

  const insurance = computed(() => {
    return store.state.insurances;
  });

  const review_info: any = computed(() => {
    return store.state.review.review_details;
  });

  const travellers_info = computed(() => {
    return review_info.value.travellers_info;
  });

  const premiums = review_info.value?.invoice?.total_price_without_vat;
  const selectedPremium = ref(premiums);
  const totalPremium = ref(0);

  const calculateTotalPremium = () => {
    totalPremium.value =
      parseFloat(review_info.value?.invoice?.total_price_without_vat)
  };

  onMounted(async () => {
    await Countries.getCountries();
    await Insurances.getInsuredTypes();
    await Quotes.getVat();

    const selected_premium = window.localStorage.getItem("selected_premium");
    if (selected_premium) {
      const data = JSON.parse(selected_premium);
      selectedPremium.value = data;
    }
    window.localStorage.setItem('traveller_index', '0');

    let uuid = router.currentRoute.value.query ? router.currentRoute.value.query.uuid : null;
    if (!uuid) uuid = window.localStorage.getItem('uuid')
    if (uuid) {
      const payload = {
        uuid: uuid,
      };
      await reviewService.getDetails(payload);
    }
    calculateTotalPremium();
  });
  

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_h_review_member_form = _resolveComponent("h-review-member-form")!
  const _component_h_page_loader = _resolveComponent("h-page-loader")!

  return (review_info.value && review_info.value?.invoice)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("review_details")), 1),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("qoute_reference")) + " ", 1),
                  _createElementVNode("strong", null, _toDisplayString(review_info.value?.invoice?.quote_ref_no), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("img", {
                          src: `${domain.value}${url.value}/img/${review_info.value?.invoice?.get_provider_detail?.image}`,
                          class: "img-fluid"
                        }, null, 8, _hoisted_12)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("h5", _hoisted_15, _toDisplayString(review_info.value?.invoice?.insurance_plan_name) + " - " + _toDisplayString(review_info.value?.invoice?.insurance_coverage_name) + " - " + _toDisplayString(review_info.value?.invoice?.insurance_cover_for), 1)
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "icon" }, [
                              _createElementVNode("img", {
                                src: _imports_0,
                                class: "img-fluid"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, " (" + _toDisplayString(_ctx.$t("from")) + " " + _toDisplayString(_unref(setDateMdY)(review_info.value.start_date)) + " " + _toDisplayString(_ctx.$t("to")) + " " + _toDisplayString(_unref(setDateMdY)(review_info.value.end_date)) + ") ", 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "icon" }, [
                              _createElementVNode("img", {
                                src: _imports_1,
                                class: "img-fluid"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["text", {'wrap-content' : _unref(getTextWidth)(review_info.value.destination_country) > 150 ? true : false }])
                              }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("destination_label")) + " : ", 1),
                                _createVNode(_component_el_tooltip, {
                                  class: "box-item",
                                  effect: "light",
                                  disabled: _unref(getTextWidth)(review_info.value.destination_country) > 150 ? false : true
                                }, {
                                  content: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_22, _toDisplayString(review_info.value.destination_country), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(review_info.value.destination_country), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ], 2)
                            ])
                          ])
                        ]),
                        (review_info.value?.invoice?.highlight)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(review_info.value?.invoice
                                ?.highlight, (highlight, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                  (highlight.section == 1)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                        _createElementVNode("p", null, [
                                          _createTextVNode(_toDisplayString(language.value === "ar"
                              ? highlight.label_ar
                              : highlight.label) + " ", 1),
                                          _createElementVNode("span", null, _toDisplayString(highlight && highlight.value ? highlight.value : '-'), 1)
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t("total_premium")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_29, [
                              _createElementVNode("h4", _hoisted_30, _toDisplayString(_ctx.currency.format(totalPremium.value)), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t("no_of_traveller_placeholder")) + " : " + _toDisplayString(travellers_info.value?.length), 1)
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(travellers_info.value, (traveller, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "result-listing__item__block",
                      key: index
                    }, [
                      _createVNode(_component_h_review_member_form, {
                        item: traveller,
                        counting: index,
                        insurance: insurance.value,
                        review_info: review_info.value,
                        ref_for: true,
                        ref: "travellerForm"
                      }, null, 8, ["item", "counting", "insurance", "review_info"])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("section", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('wait')), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_h_page_loader)
      ], 64))
}
}

})