
import { App } from "vue";

/** Insurance Type */
// const myself = 1;
const domestics = 2;
const family = 3;
const parent = 5;

/** Emirates */
// const dubai = 3;

export const rule = (app: App<Element>) => {
    app.config.globalProperties.rule = {
        members(insurance_type_id: number) {
            return [parent, family, domestics].includes(insurance_type_id);
        },

        go_thank_you() {
            return false;
        }
    }
}