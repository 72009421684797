/* eslint-disable */
import { GetterTree } from 'vuex';

import { RootState } from '@/store';

// import { Generics } from '@/core/types';

import { State } from './state';

export type Getters = {
  getQuotes(state: State): any | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getQuotes: (state) =>  {
    return state.quotes
  },
};
