import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "vechile-details-list" }
const _hoisted_2 = { class: "row form-block travel-date" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "row form-block travel-date" }
const _hoisted_5 = { class: "col-md-6" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "row form-block travel-date" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "col-md-6" }
const _hoisted_10 = { class: "row form-block travel-date" }
const _hoisted_11 = { class: "col-md-6" }
const _hoisted_12 = { class: "col-md-6 counter-element" }
const _hoisted_13 = { class: "form-block members-info-travel" }
const _hoisted_14 = { class: "label-block" }
const _hoisted_15 = { class: "label-block_label" }

import { computed, getCurrentInstance, onMounted, reactive, ref, watchEffect } from "vue";
  import { formPersonalDetails } from "@/store/stateless";
  import { store } from "@/store";
  import Validations from "@/core/validations";
  import { focus, dates, trip_dates_filter } from "@/store/stateless"
  // import personalService from "@/core/services/PersonalDetails";
  import { Traveller } from "@/core/types";
  import Insurances from "@/core/services/Insurances";
  import Countries from "@/core/services/Countries";
  import moment from "moment";
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TravellerForm',
  setup(__props, { expose: __expose }) {

  const showMembers = ref(true);
  const showErrorMinTraveller = ref(0);

  const name = ref()
  const destination_id = ref()

  const language = computed(() => {
    return window.localStorage.getItem('language');
  })
  
  const countries = computed(() => {
    let allCountries = store.state.countries.countries;
    return allCountries.filter((e)=>e.id !== uaeId.value )
  });
  
  const max_traveller_count = computed(() => {
    return store.state.insurances.max_traveller_count;
  });
  
  const uaeId = computed(() => {
    return store.state.countries.uae_id;
  });
  
  const customer = computed(() => {
    return store.state.personals.customer;
  });
  
  const recent_searches = computed(() => {
    return store.state.personals.recent_searches;
  });
  
  const loading = computed(() => {
    return store.state.personals.loading;
  });

  const rules = reactive({
    name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
    phone_number: [
      { validator: Validations.phone_number, trigger: ["blur", "change"] },
    ],
    start_date: [
      { validator: Validations.start_date, trigger: ["blur", "change"] },
    ],
    end_date: [
      { validator: Validations.end_date, trigger: ["blur", "change"] },
    ],
    destination_id: [{ validator: Validations.destination, trigger: ["blur", "change"] }],
    source_id: [{ validator: Validations.source, trigger: ["blur", "change"] }],
    email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
  });

  const getLatestLeads = async () => {
    // const device_uuid = window.localStorage.getItem('device_uuid');
    // const payload = {
    //   device_uuid: device_uuid
    // }
    // return await personalService.getLatestLeads(payload)
  }

  onMounted(async () => {
    name.value.focus()
    window.localStorage.setItem("traveller_index", JSON.stringify(0));
    await Countries.getCountries();
    await Insurances.getInsuredTypes();
    const device_uuid = window.localStorage.getItem('device_uuid');
    if (device_uuid) await getLatestLeads();
    else {
      const find = recent_searches.value.find(x => x.bai_misc_lead_id === customer.value.id);
      if(find) {
        find.stage = find.stage ?? 1;
        find.insurance_provider_id = find.insurance_provider_id ?? null;
        find.plan_id = find.plan_id ?? null;
        find.plan_ref_id = find.plan_ref_id ?? null;
        find.last_visit = moment().format('YYYY-MM-DD');
        find.device_uuid = find.device_uuid ?? (device_uuid != '' ? device_uuid : null);
        find.bai_misc_lead_id = find.bai_misc_lead_id ?? customer.value.id;
        find.status = find.status ?? 0;
        find.lead_details = customer.value.travellers_info;
      }
    }
    if(!customer.value.inbound_outbound) {
      customer.value.destination_id = [uaeId.value];
    } else {
      customer.value.source_id = uaeId.value;
    }

    

    memeberAction();
  })

  const memeberAction = () => {
    if(customer.value.no_of_traveller == 1 && !customer.value.travellers_info.length) {
      addNewMemberForm();
    } else if((customer.value.no_of_traveller != customer.value.travellers_info.length) && (customer.value.no_of_traveller > customer.value.travellers_info.length)) {
      addNewMemberForm();
    } else if(customer.value.no_of_traveller < customer.value.travellers_info.length) {
      deleteMemberV(customer.value.travellers_info, (customer.value.travellers_info.length - 1))
    }
  }


  const memberForm = ref();

  const inst = getCurrentInstance()
  watchEffect(async () => {
    if (focus.personal !== '') {
      // eslint-disable-next-line
      const toFocus: any = inst?.refs[focus.personal]
      if (toFocus !== undefined) {
        toFocus.focus()
      }
    }
    if(customer.value.no_of_traveller && customer.value.travellers_info.length && (customer.value.no_of_traveller != customer.value.travellers_info.length)) memeberAction();

    const travellerLength = window.localStorage.getItem('travellerLength');
    if(travellerLength) 
      showErrorMinTraveller.value = parseInt(travellerLength);
    if(customer.value.travellers_info.length > 1) {
      if(customer.value.product_type == 1) {
        customer.value.product_type = 2;
      }
      
      if(showErrorMinTraveller.value != 2 && showErrorMinTraveller.value != 1) {
        window.localStorage.removeItem('travellerLength');
      }
    }
    if(
      customer.value.start_date !== 'Invalid date' && customer.value.start_date != undefined && customer.value.start_date != ''  &&
      customer.value.end_date !== 'Invalid date' && customer.value.end_date != undefined && customer.value.end_date != '' 
      ) {
        const noOfDays = calculateDays(customer.value.start_date, customer.value.end_date);
        if(noOfDays >= 0) {
          customer.value.no_of_days = noOfDays;
        } else {
          customer.value.end_date = '';
        }
      }
    if (customer.value && customer.value.travellers_info.length == 1) {
      const callBack = window.localStorage.getItem('callBack');
      if (callBack)
        showMembers.value = true
    }

    if(customer.value.start_date == '' && customer.value.end_date) customer.value.end_date = ''

  })

  const deleteMemberV = (members: Array<Traveller>, index: number) => {
    const customer = store.state.personals.customer
    if(members[index].id != null) {
        customer.deleted_members.push(members[index])
    }
    members.splice(index, 1);
    if(members.length == 1) {
        customer.product_type = 1;
    }
  }

  const setSourceOrDestination = () => {
    if(!customer.value.inbound_outbound) {
      customer.value.destination_id = [];
      customer.value.source_id = uaeId.value;
    } else {
      customer.value.destination_id = [uaeId.value];
      customer.value.source_id = null;
    }
    customer.value.inbound_outbound = !customer.value.inbound_outbound;
  }

  const calculateDays = (start_date, end_date) => {
    let dateOne = moment(end_date);
    let dateTwo = moment(start_date);
    return dateOne.diff(dateTwo, 'days', true);
  }

  

  const getSplitName = (name, position) => {
    if(name != '' && name != undefined && name != null) {
      let nameSplit =  name.split(" ");
      if(nameSplit[position] != undefined) {
        if(position == 1) {
          nameSplit.shift();
          return nameSplit.join(' ');
        } else {
          return nameSplit[position];
        }
      }
    }
    return "";
  }

  const updateSponsorValues = () => {
    customer.value.travellers_info = customer.value.travellers_info.map((x) => {
      if (x.relationship_type_id === 5) {
        x.first_name = getSplitName(customer.value.name, 0);
        x.last_name = getSplitName(customer.value.name, 1);
        x.is_editable = true;
      }
      return x;
    })
  }

  const addNewMemberForm = () => {
    showMembers.value = true;
    if(customer.value.product_type != 1) {
      customer.value.product_type = 2;
    }
    customer.value.travellers_info.push({
      id: null,
      bai_misc_lead_id: 0,
      type: '',
      first_name: (customer.value.travellers_info.length == 0) ? getSplitName(customer.value.name, 0) : '',
      middle_name: '',
      last_name: (customer.value.travellers_info.length == 0) ? getSplitName(customer.value.name, 1) : '',
      dob: '',
      date_of_birth: '',
      is_field: 0,
      passport: '',
      gender_id: null,
      insurance_type: 0,
      marital_status_id: null,
      country_id: null,
      relationship_type_id: (customer.value.travellers_info.length == 0 ) ? 5 : null,
      is_editable: true
    })
  }
  
  const resetMemberForm = () => {
    showMembers.value = true;
    customer.value.travellers_info = [{
      id: null,
      bai_misc_lead_id: 0,
      type: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      dob: '',
      is_field: 0,
      passport: '',
      date_of_birth:'',
      gender_id: null,
      insurance_type: 0,
      marital_status_id: null,
      country_id: null,
      relationship_type_id: 5,
      is_editable: true
    }]
  }

  const end_date_mix = computed(() => {
    if(customer.value.start_date) return customer.value.start_date

    return dates.expected_date
  })

  __expose({
    resetMemberForm
  })

return (_ctx: any,_cache: any) => {
  const _component_h_inbound_outbound = _resolveComponent("h-inbound-outbound")!
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_date_v2 = _resolveComponent("h-date-v2")!
  const _component_h_nationality = _resolveComponent("h-nationality")!
  const _component_h_member_form = _resolveComponent("h-member-form")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref_key: "formPersonalDetails",
    ref: formPersonalDetails,
    model: customer.value,
    "status-icon": "",
    rules: rules,
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_h_inbound_outbound, {
          label: _ctx.$t('inbound_outbound'),
          labela: _ctx.$t('uae'),
          modelValue: customer.value.inbound_outbound,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((customer.value.inbound_outbound) = $event)),
          onSetValue: _cache[1] || (_cache[1] = ($event: any) => (setSourceOrDestination()))
        }, null, 8, ["label", "labela", "modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_h_input, {
              isRequired: "",
              label: _ctx.$t('full_name'),
              isType: "text",
              "form-prop": "name",
              modelValue: customer.value.name,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((customer.value.name) = $event)),
              "max-length": "62",
              onkeydown: language.value === 'en' ? `return /[a-zA-Z\s' ]/i.test(event.key)` : '',
              "tooltip-message": _ctx.$t('full_name_tooltip'),
              ref_key: "name",
              ref: name,
              placeHolder: _ctx.$t('full_name'),
              onChange: _cache[3] || (_cache[3] = ($event: any) => (updateSponsorValues()))
            }, null, 8, ["label", "modelValue", "onkeydown", "tooltip-message", "placeHolder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_h_input, {
              isRequired: "",
              label: _ctx.$t('phone_number'),
              "is-type": "phone",
              placeHolder: _ctx.$t('phone_number_placeholder'),
              "form-prop": "phone_number",
              "model-value": customer.value.phone_number,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((customer.value.phone_number) = $event)),
              "country-code": customer.value.phone_country_code,
              "onUpdate:countryCode": _cache[5] || (_cache[5] = ($event: any) => ((customer.value.phone_country_code) = $event)),
              ref: "phone_number"
            }, null, 8, ["label", "placeHolder", "model-value", "country-code"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_h_input, {
              isRequired: "",
              label: _ctx.$t('email_address'),
              isType: "text",
              formProp: "email",
              placeHolder: _ctx.$t('email_address_placeholder'),
              modelValue: customer.value.email,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((customer.value.email) = $event)),
              maxLength: "62",
              ref: "email"
            }, null, 8, ["label", "placeHolder", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_h_date_v2, {
              isRequired: "",
              modelValue: customer.value.start_date,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((customer.value.start_date) = $event)),
              "is-tooltip": "",
              dayProp: "start_date",
              "tooltip-message": _ctx.$t('start_date_tooltip'),
              "form-prop": "start_date",
              label: _ctx.$t('start_date'),
              ref: "start_date",
              min: _unref(dates).expected_date,
              max: _unref(trip_dates_filter)(_unref(dates).expected_date)
            }, null, 8, ["modelValue", "tooltip-message", "label", "min", "max"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_h_date_v2, {
              isRequired: "",
              modelValue: customer.value.end_date,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((customer.value.end_date) = $event)),
              "is-tooltip": "",
              dayProp: "end_date",
              "tooltip-message": _ctx.$t('end_date_tooltip'),
              "form-prop": "end_date",
              label: _ctx.$t('end_date'),
              ref: "end_date",
              min: end_date_mix.value,
              defaultValue: customer.value.start_date,
              max: _unref(trip_dates_filter)(end_date_mix.value)
            }, null, 8, ["modelValue", "tooltip-message", "label", "min", "defaultValue", "max"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (!customer.value.inbound_outbound)
              ? (_openBlock(), _createBlock(_component_h_nationality, {
                  key: 0,
                  "is-required": "",
                  "tooltip-message": _ctx.$t('source_tooltip'),
                  "is-tooltip": "",
                  label: _ctx.$t('source'),
                  modelValue: customer.value.source_id,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((customer.value.source_id) = $event)),
                  formProp: "source_id",
                  ref: "source_id",
                  options: countries.value
                }, null, 8, ["tooltip-message", "label", "modelValue", "options"]))
              : _createCommentVNode("", true),
            (customer.value.inbound_outbound)
              ? (_openBlock(), _createBlock(_component_h_input, {
                  key: 1,
                  "is-required": "",
                  label: _ctx.$t('destination'),
                  "is-type": "multiselect",
                  "form-prop": "destination_id",
                  modelValue: customer.value.destination_id,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((customer.value.destination_id) = $event)),
                  options: countries.value,
                  "is-tooltip": "",
                  "tooltip-message": _ctx.$t('destination_tooltip'),
                  ref_key: "destination_id",
                  ref: destination_id,
                  onChange: _cache[11] || (_cache[11] = ($event: any) => (destination_id.value.name.query = ''))
                }, null, 8, ["label", "modelValue", "options", "tooltip-message"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_h_input, {
              isRequired: "",
              label: _ctx.$t('no_of_traveller'),
              isType: "step",
              min: "1",
              max: max_traveller_count.value,
              "is-tooltip": "",
              "tooltip-message": _ctx.$t('minimum_traveller_is_required'),
              formProp: "no_of_traveller",
              placeHolder: _ctx.$t('no_of_traveller_placeholder'),
              modelValue: customer.value.no_of_traveller,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((customer.value.no_of_traveller) = $event)),
              ref: "no_of_traveller"
            }, null, 8, ["label", "max", "tooltip-message", "placeHolder", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("member_dob")) + " * ", 1)
            ])
          ]),
          (showMembers.value)
            ? (_openBlock(), _createBlock(_component_h_member_form, {
                key: 0,
                customer: customer.value,
                members: customer.value.travellers_info,
                ref_key: "memberForm",
                ref: memberForm
              }, null, 8, ["customer", "members"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_h_proceed, {
          onHandleClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.personalDetails.click(_unref(formPersonalDetails)))),
          fullscreenLoading: loading.value,
          disabled: ""
        }, null, 8, ["fullscreenLoading"])
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}
}

})