import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/whatsapp_footer.png'


const _hoisted_1 = { class: "vechile-list page-content" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "vechile-list-container main-container" }
const _hoisted_4 = { class: "vechile-list_row" }
const _hoisted_5 = { class: "result-listing-full-block" }
const _hoisted_6 = { class: "text-center no-result-block" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('thank_you')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('callback_thank_you_msg')), 1),
              _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('no_quotes_manual_quote_title')) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("a", {
                  href: "https://api.whatsapp.com/send?phone=971557616131",
                  target: "_blank"
                }, [
                  _createElementVNode("img", {
                    height: "25",
                    width: "25",
                    src: _imports_0
                  })
                ], -1)),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("a", { href: _ctx.homePage }, _toDisplayString(_ctx.$t('go_back')), 9, _hoisted_7)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}