import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-0 submit-btn text-md-end text-center proceed-button" }
const _hoisted_2 = { class: "col-lg-12" }
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["row", {'justify-content-center': _ctx.isCenter}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          class: "btn p-0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleClick'))),
          "element-loading-background": "rgba(0, 0, 0, 0.4)"
        }, {
          default: _withCtx(() => [
            (!_ctx.fullscreenLoading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("proceed")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.$t("wait")) + " ", 1),
                  _cache[1] || (_cache[1] = _createElementVNode("span", {
                    class: "spinner-border spinner-border-sm",
                    role: "status",
                    "aria-hidden": "true"
                  }, null, -1))
                ]))
          ]),
          _: 1
        })), [
          [
            _directive_loading,
            _ctx.fullscreenLoading,
            void 0,
            {
              fullscreen: true,
              lock: true
            }
          ]
        ])
      ])
    ], 2)
  ]))
}