import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-block" }
const _hoisted_2 = { class: "label-block" }
const _hoisted_3 = { class: "label-block_label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "label-block_question position-relative"
}
const _hoisted_6 = { class: "tooltip-content" }
const _hoisted_7 = {
  key: 0,
  class: "input-block"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 1,
  class: "input-block"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 2,
  class: "input-block-phone"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  key: 3,
  class: "input-block"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  key: 4,
  class: "input-block"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = {
  key: 5,
  class: "input-block"
}
const _hoisted_18 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form_item, {
          prop: `${_ctx.formProp}_label`
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", null, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.isRequired)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["prop"])
      ]),
      (_ctx.isTooltip)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "light"
            }, {
              content: _withCtx(() => [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.tooltipMessage), 1)
              ]),
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("a", {
                  class: "popper",
                  "data-bs-trigger": "hover",
                  placement: "right",
                  "data-bs-toggle": "popover"
                }, [
                  _createElementVNode("div", { class: "icon" }, [
                    _createElementVNode("span", { class: "icon-question-icon" })
                  ])
                ], -1))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isType === 'text')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.colSize)
            }, [
              _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    "model-value": _ctx.modelValue,
                    placeholder: _ctx.placeHolder,
                    maxlength: _ctx.maxLength,
                    onkeydown: _ctx.onKey,
                    onInput: _ctx.updateValue,
                    ref: "name"
                  }, null, 8, ["model-value", "placeholder", "maxlength", "onkeydown", "onInput"])
                ]),
                _: 1
              }, 8, ["prop"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isType === 'number')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.colSize)
            }, [
              _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    "model-value": _ctx.modelValue,
                    placeholder: _ctx.placeHolder,
                    maxlength: _ctx.maxLength,
                    onkeydown: _ctx.onKey,
                    onInput: _ctx.updateValue,
                    type: "tel",
                    oninput: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '')",
                    pattern: "[0-9]*",
                    ref: "name"
                  }, null, 8, ["model-value", "placeholder", "maxlength", "onkeydown", "onInput"])
                ]),
                _: 1
              }, 8, ["prop"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isType === 'phone')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.colSize)
            }, [
              _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    "model-value": _ctx.modelValue,
                    onInput: _ctx.updateValue,
                    type: "tel",
                    maxlength: "10",
                    pattern: "[0-9]*",
                    placeholder: _ctx.placeHolder,
                    id: "phone_number",
                    oninput: "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = parseInt(this.value,10); this.value = this.value.replace(/[^0-9]/g, '')",
                    ref: "name",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change')))
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        "model-value": _ctx.countryCode,
                        onChange: _ctx.updateCountryValue,
                        filterable: "",
                        class: "country"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: item.text,
                              value: item.text
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["model-value", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["model-value", "onInput", "placeholder"])
                ]),
                _: 1
              }, 8, ["prop"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isType === 'select')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.colSize)
            }, [
              _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    "model-value": _ctx.modelValue,
                    filterable: !_ctx.isMobile,
                    placeholder: _ctx.placeHolder,
                    class: "select-input",
                    onChange: _ctx.updateValue,
                    ref: "name"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          class: "vehicle",
                          key: index,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["model-value", "filterable", "placeholder", "onChange"])
                ]),
                _: 1
              }, 8, ["prop"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isType === 'multiselect')
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.colSize)
            }, [
              _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    "model-value": _ctx.modelValue,
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                    "collapse-tags-tooltip": "",
                    placeholder: _ctx.placeHolder,
                    class: "select-input",
                    onChange: _ctx.updateValue,
                    ref: "name"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          class: "vehicle",
                          key: index,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["model-value", "placeholder", "onChange"])
                ]),
                _: 1
              }, 8, ["prop"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isType === 'step')
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.colSize)
            }, [
              _createVNode(_component_el_input_number, {
                min: _ctx.min,
                max: _ctx.max,
                "model-value": _ctx.modelValue,
                placeholder: _ctx.placeHolder,
                onChange: _ctx.stepChange,
                ref: "name",
                name: "steppers"
              }, null, 8, ["min", "max", "model-value", "placeholder", "onChange"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}