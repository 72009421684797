/* eslint-disable */
import { App } from "vue";
import { FormInstance } from "element-plus";
import { Traveller } from "@/core/types";
import reviewService from "@/core/services/ReviewDetails";
import { store } from "@/store";
import { focus, loading } from "@/store/stateless"
import Validations from "@/core/validations";
import router from "@/router";

export const reviewDetails = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.reviewDetails = {
        async click(formEl: FormInstance) {
            focus.review = ''
            const current_indext:any = window.localStorage.getItem('traveller_index');

            if (!formEl) return;
            formEl.validate(async (valid, fields: any) => {
                reviewService.setLoading(true);
                if (valid) {
                    if(!loading.value) {
                        loading.value = true
                        let traveller_index = window.localStorage.getItem('traveller_index')
                        const traveller_id = traveller_index ? parseInt(traveller_index) : 0;
                        console.log({traveller_id});
                        const traveller = store.state.review.review_details.travellers_info[traveller_id];
                        if(store.state.review.review_details.travellers_info[traveller_id + 1] != undefined) {
                            setTimeout(() => {
                                traveller.is_editable = false
                                store.state.review.review_details.travellers_info[traveller_id + 1].is_editable = true;
                                loading.value = false
                            }, 500);
                        }
                        const invoice = store.state.review.review_details.invoice;
                        const insurance_id = (invoice as any).insurance_provider_id ?? 0;
                        const agent = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";
                        const sessionId = window.localStorage.getItem('session_id');
                        const payload = {
                            traveller: traveller,
                            insurance_id: insurance_id,
                            agent_id: agent ? parseInt(agent) : null,
                            session_id: sessionId ?? ''
                        }
                        const result = await reviewService.post(payload);
                        if (result && result.status !== 200 && result.data) {
                            formEl.validate(async (valid, field: any) => {
                                focus.review = ''
                                // formEl.scrollToField(Object.keys(field)[0])
                                focus.review = Object.keys(field)[0]
                            })
                        }
                        window.localStorage.setItem('traveller_index', JSON.stringify((parseInt(current_indext) + 1)))
                        reviewService.setLoading(false);
                    }
                } else {
                    focus.review = ''
                    const firstError = Object.keys(fields)[0]
                    formEl.scrollToField(`${firstError}_label`)
                    focus.review = Object.keys(fields)[0]
                    console.log({ focus })
                }
            })
        },
        reset(formEl: FormInstance | undefined) {
            if (!formEl) return
            formEl.clearValidate()
        }
    }
}