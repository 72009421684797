import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vechile-detail-section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "vechile-detail-container" }
const _hoisted_4 = { class: "vechile-detail_row" }
const _hoisted_5 = { class: "row gx-5 justify-content-center" }
const _hoisted_6 = { class: "col-lg-9" }
const _hoisted_7 = { class: "vechile-detail__block" }
const _hoisted_8 = { class: "row justify-content-center" }
const _hoisted_9 = { class: "col-lg-11 col-md-7 form-left-column" }
const _hoisted_10 = { class: "vechile-details-form" }
const _hoisted_11 = { class: "steps-listing__details" }
const _hoisted_12 = { class: "quotes-list" }
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('some_extra_information')), 1),
                            _createElementVNode("h4", null, [
                              _createElementVNode("span", {
                                innerHTML: _ctx.$t('no_declaration_que_ans')
                              }, null, 8, _hoisted_14)
                            ]),
                            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                            _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
                            _createElementVNode("p", null, [
                              _createElementVNode("span", {
                                innerHTML: _ctx.$t('alternatively_call_us')
                              }, null, 8, _hoisted_15),
                              _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                              _createElementVNode("a", { href: _ctx.homePage }, _toDisplayString(_ctx.$t('go_back')), 9, _hoisted_16)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}