import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wizard-navigation health-wizard d-flex flex-wrap justify-content-center d-none d-lg-flex d-xl-flex" }
const _hoisted_2 = { class: "wizard-navigation_item--title" }
const _hoisted_3 = { class: "wizard-navigation_item--title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["wizard-navigation_item position-relative", {active: _ctx.$route.name === 'PersonalDetails'}]),
      style: {"cursor":"pointer"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.header.go(1)))
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "wizard-navigation_item--number" }, "1", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("personal_details")), 1)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["wizard-navigation_item position-relative", {active: _ctx.$route.name === 'ThankYou'}]),
      style: {"cursor":"pointer"}
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "wizard-navigation_item--number" }, "3", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("thank_you")), 1)
      ])
    ], 2)
  ]))
}