import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "input-block member-details-block overflow-visible" }
const _hoisted_2 = { class: "member-details-container" }
const _hoisted_3 = { class: "row form-block icon-date-picker1 apply-font travel-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_date_v2 = _resolveComponent("h-date-v2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (traveller, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-md-6 mt-5",
              key: index
            }, [
              _createVNode(_component_h_date_v2, {
                isRequired: "",
                rules: {validator: _ctx.v_dob, trigger: ['change', 'blur']},
                dayProp: `members.${index}.dob`,
                "form-prop": "dob",
                modelValue: traveller.dob,
                "onUpdate:modelValue": ($event: any) => ((traveller.dob) = $event),
                label: _ctx.$t('traveller_dob_label', { count: (index + 1) }),
                ref_for: true,
                ref: `members.${index}.dob`,
                defaultValue: _ctx.defaultDob,
                max: _ctx.dDate.today,
                min: _ctx.dDate.min_age
              }, null, 8, ["rules", "dayProp", "modelValue", "onUpdate:modelValue", "label", "defaultValue", "max", "min"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}