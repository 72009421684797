import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "result-listing__item lowest-price position-relative"
}
const _hoisted_2 = {
  class: "result-listing__item__block overflow-hidden result-listing-custom travel-quotes",
  id: "travelQuotes"
}
const _hoisted_3 = { class: "result-listing__item__block-top" }
const _hoisted_4 = { class: "row g-0" }
const _hoisted_5 = { class: "left-col w-100" }
const _hoisted_6 = { class: "result-listing__item__block_left h-100" }
const _hoisted_7 = { class: "result-listing__item__block_top p-0" }
const _hoisted_8 = { class: "result-listing__item__block_top_row p-0" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-10" }
const _hoisted_11 = { class: "result-listing__item__block_top-container d-flex flex-wrap align-items-center" }
const _hoisted_12 = { class: "partner-logo" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "partner-content" }
const _hoisted_15 = { class: "partner-content_title" }
const _hoisted_16 = { class: "mb-0" }
const _hoisted_17 = {
  key: 0,
  class: "partner-content_bottom row d-flex justify-content-between"
}
const _hoisted_18 = {
  key: 1,
  class: "partner-content_bottom row d-flex justify-content-between"
}
const _hoisted_19 = { class: "partner-content_bottom_item col-md-4" }
const _hoisted_20 = { class: "plan-details" }
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "col-md-2" }
const _hoisted_23 = { class: "right-buy-now text-center traveller_right_block" }
const _hoisted_24 = { class: "right-buy-now-top" }
const _hoisted_25 = { class: "premium_block" }
const _hoisted_26 = { class: "premium-title" }
const _hoisted_27 = { class: "price-title" }
const _hoisted_28 = { class: "mb-0" }
const _hoisted_29 = { class: "traveller_block" }
const _hoisted_30 = { class: "no_of_traveller" }
const _hoisted_31 = { class: "buy-now-button" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = {
  key: 0,
  class: "right-checkbox-container"
}
const _hoisted_34 = { class: "custom-checkbox" }
const _hoisted_35 = ["id", "name", "disabled"]
const _hoisted_36 = ["for"]
const _hoisted_37 = { class: "result-listing__item__block_medium listing-block-ui" }
const _hoisted_38 = { class: "row" }
const _hoisted_39 = {
  key: 0,
  class: "col-md-6"
}
const _hoisted_40 = { class: "listing-ul" }
const _hoisted_41 = {
  key: 1,
  class: "col-md-6 col-sm-12 add-ons-block"
}
const _hoisted_42 = { class: "listing-ul" }
const _hoisted_43 = { class: "row" }
const _hoisted_44 = ["for"]
const _hoisted_45 = ["onUpdate:modelValue", "onChange", "id"]
const _hoisted_46 = { class: "add-on-label" }
const _hoisted_47 = ["open"]
const _hoisted_48 = {
  key: 0,
  class: "result-listing__item__block-bottom",
  id: "scrollbar"
}
const _hoisted_49 = { class: "result-listing__item__block-bottom-top" }
const _hoisted_50 = { class: "row" }
const _hoisted_51 = { class: "listing-ul" }
const _hoisted_52 = {
  key: 0,
  class: "text-center btn-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_ctx.expand)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("img", {
                                src: `${_ctx.domain}${_ctx.url}/img/${_ctx.quote?.provider.image}`,
                                class: "img-fluid"
                              }, null, 8, _hoisted_13)
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("h5", _hoisted_16, _toDisplayString(_ctx.quote.paln_name) + " - " + _toDisplayString(_ctx.quote.coverage_name) + " - " + _toDisplayString(_ctx.quote.product_type_name), 1)
                              ]),
                              (_ctx.moreDetailsSection(_ctx.quote, 1).length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moreDetailsSection(
                          _ctx.quote,
                          1
                        ), (highlight, index) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: index,
                                        class: "partner-content_bottom_item col-md-4"
                                      }, [
                                        _createElementVNode("p", null, [
                                          _createTextVNode(_toDisplayString(_ctx.language === "ar"
                                  ? highlight.label_ar
                                  : highlight.label) + " ", 1),
                                          _createElementVNode("span", null, _toDisplayString(highlight.value ? highlight.value : '-'), 1)
                                        ])
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.quote.planDocuments)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "partner-content_bottom_item col-md-4" }, null, -1)),
                                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "partner-content_bottom_item col-md-4" }, null, -1)),
                                    _createElementVNode("div", _hoisted_19, [
                                      _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("a", {
                                          href: _ctx.quote.planDocuments.file_url,
                                          target: "_blank"
                                        }, [
                                          _createTextVNode(_toDisplayString(_ctx.$t("plan_benefits")) + " ", 1),
                                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "icon-chevron-right" }, null, -1))
                                        ], 8, _hoisted_21)
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("div", _hoisted_24, [
                              _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, [
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("total_premium")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_27, [
                                  _createElementVNode("h4", _hoisted_28, _toDisplayString(_ctx.currency.format(_ctx.quote.premium_round)), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t("no_of_traveller_placeholder")) + " : " + _toDisplayString(_ctx.leadInfo.no_of_traveller), 1)
                              ]),
                              _createElementVNode("div", _hoisted_31, [
                                _createElementVNode("a", {
                                  href: "#",
                                  disabled: _ctx.quote.premium_round <= 0,
                                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.BuyNow(_ctx.quote)), ["prevent"])),
                                  class: "btn"
                                }, _toDisplayString(_ctx.$t("buy_now")), 9, _hoisted_32)
                              ]),
                              (!_ctx.isMobile)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                    _createElementVNode("div", _hoisted_34, [
                                      _createElementVNode("input", {
                                        class: "styled-checkbox",
                                        id: 'add-to-compare' + _ctx.quote.id + _ctx.quote.coverage_id + _ctx.quote.provider_plan_id,
                                        name: 'add-to-compare' + _ctx.quote.id + _ctx.quote.coverage_id + _ctx.quote.provider_plan_id,
                                        type: "checkbox",
                                        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addToCompare', _ctx.quote))),
                                        disabled: _ctx.compareList.length >= 3  && _ctx.compareList.indexOf(_ctx.quote) == -1
                                      }, null, 40, _hoisted_35),
                                      _createElementVNode("label", {
                                        for: 'add-to-compare' + _ctx.quote.id + _ctx.quote.coverage_id + _ctx.quote.provider_plan_id
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("add_compare")), 1)
                                      ], 8, _hoisted_36)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      (_ctx.moreDetailsSection(_ctx.quote, 2).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                            _createElementVNode("div", _hoisted_40, [
                              _createElementVNode("ul", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moreDetailsSection(
                            _ctx.quote,
                            2
                          ), (highlight, index) => {
                                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                                    _createTextVNode(_toDisplayString(_ctx.language === "ar"
                            ? highlight.label_ar
                            : highlight.label) + " ", 1),
                                    _createElementVNode("span", null, _toDisplayString(highlight && highlight.value ? highlight.value : '-'), 1)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.quote?.addon && _ctx.quote?.addon.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, [
                              _createElementVNode("h6", null, _toDisplayString(_ctx.$t("extra_addon")), 1),
                              _createElementVNode("div", _hoisted_43, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quote?.addon, (addon, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "col-md-6",
                                    key: index
                                  }, [
                                    _createElementVNode("label", {
                                      for: `value_personal_driver_${_ctx.position}_${index}`
                                    }, [
                                      _withDirectives(_createElementVNode("input", {
                                        type: "checkbox",
                                        "onUpdate:modelValue": ($event: any) => ((addon.addonCheck) = $event),
                                        onChange: ($event: any) => (_ctx.addonAdd(addon, _ctx.quote)),
                                        id: `value_personal_driver_${_ctx.position}_${index}`
                                      }, null, 40, _hoisted_45), [
                                        [_vModelCheckbox, addon.addonCheck]
                                      ]),
                                      _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.language === 'ar' ? addon.label : addon.label) + " " + _toDisplayString(_ctx.currency.format(addon.value_to_display)), 1)
                                    ], 8, _hoisted_44)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", { open: _ctx.moreDetails }, [
            (_ctx.moreDetails)
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _createElementVNode("div", _hoisted_49, [
                    _createVNode(_component_el_scrollbar, { height: "120px" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_50, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moreDetailsSection(_ctx.quote, 3), (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "col-lg-3 col-md-3"
                            }, [
                              _createElementVNode("div", _hoisted_51, [
                                _createElementVNode("ul", null, [
                                  _createElementVNode("li", null, [
                                    _createTextVNode(_toDisplayString(_ctx.language === "ar" ? item.label_ar : item.label) + " ", 1),
                                    _createElementVNode("span", null, _toDisplayString(item && item.value ? item.value : '-'), 1)
                                  ])
                                ])
                              ])
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_47),
          (_ctx.quote && _ctx.quote?.highlight && _ctx.quote?.highlight.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                _createElementVNode("a", {
                  class: "sliding-button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.moreDetails = !_ctx.moreDetails))
                }, _toDisplayString(!_ctx.moreDetails ? _ctx.$t("show_more") : _ctx.$t("show_less")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}