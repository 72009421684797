import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-block" }
const _hoisted_2 = { class: "label-block" }
const _hoisted_3 = { class: "label-block_label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "label-block_question position-relative"
}
const _hoisted_6 = { class: "tooltip-content" }
const _hoisted_7 = { class: "input-block" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-10" }
const _hoisted_10 = { class: "row-input" }
const _hoisted_11 = { class: "row gx-20" }
const _hoisted_12 = { class: "col-sm-4 col-4 col-md-3 col-lg-3" }
const _hoisted_13 = {
  key: 0,
  class: "col-sm-4 col-4 col-md-5 col-lg-5"
}
const _hoisted_14 = {
  key: 1,
  class: "col-sm-4 col-4 col-md-5 col-lg-5"
}
const _hoisted_15 = { class: "col-sm-4 col-4 col-md-4 col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.isTooltip)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "light"
            }, {
              content: _withCtx(() => [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.tooltipMessage), 1)
              ]),
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("a", {
                  class: "popper",
                  "data-bs-trigger": "hover",
                  placement: "right",
                  "data-bs-toggle": "popover"
                }, [
                  _createElementVNode("div", { class: "icon" }, [
                    _createElementVNode("span", { class: "icon-question-icon" })
                  ])
                ], -1))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_el_form_item, { prop: _ctx.dayProp }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      onChange: _ctx.selectedDay,
                      "model-value": _ctx.dayValue,
                      filterable: "",
                      placeholder: _ctx.dayPH,
                      class: "select-input vehicle",
                      ref: _ctx.dayProp
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates.getDays(), (item, index) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            class: "vehicle",
                            key: index,
                            label: item,
                            value: item
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["onChange", "model-value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["prop"])
              ]),
              (_ctx.screen.width > 768)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_el_form_item, { prop: _ctx.monthProp }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          onChange: _ctx.selectedMonth,
                          "model-value": _ctx.monthValue,
                          filterable: "",
                          placeholder: _ctx.monthPH,
                          class: "select-input vehicle",
                          ref: _ctx.monthProp
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.months, (item, index) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                class: "vehicle",
                                key: index,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["onChange", "model-value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["prop"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_el_form_item, { prop: _ctx.monthProp }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          onChange: _ctx.selectedMonth,
                          "model-value": _ctx.monthValue,
                          filterable: "",
                          placeholder: _ctx.monthPH,
                          class: "select-input vehicle",
                          ref: _ctx.monthProp
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.month_mobiles, (item, index) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                class: "vehicle",
                                key: index,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["onChange", "model-value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["prop"])
                  ])),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_el_form_item, { prop: _ctx.yearProp }, {
                  default: _withCtx(() => [
                    (_ctx.isDOB)
                      ? (_openBlock(), _createBlock(_component_el_select, {
                          key: 0,
                          onChange: _ctx.selectedYear,
                          "model-value": _ctx.yearValue,
                          filterable: "",
                          placeholder: _ctx.yearPH,
                          class: "select-input vehicle",
                          ref: _ctx.yearProp
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates.getYears(), (item, index) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                class: "vehicle",
                                key: index,
                                label: item,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["onChange", "model-value", "placeholder"]))
                      : (_openBlock(), _createBlock(_component_el_select, {
                          key: 1,
                          onChange: _ctx.selectedYear,
                          "model-value": _ctx.yearValue,
                          filterable: "",
                          placeholder: _ctx.yearPH,
                          class: "select-input vehicle",
                          ref: _ctx.yearProp
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates.getYearAddOne(), (item, index) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                class: "vehicle",
                                key: index,
                                label: item,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["onChange", "model-value", "placeholder"]))
                  ]),
                  _: 1
                }, 8, ["prop"])
              ]),
              _createVNode(_component_el_form_item, { prop: _ctx.formProp }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "hidden",
                    "model-value": _ctx.modelValue
                  }, null, 8, ["model-value"])
                ]),
                _: 1
              }, 8, ["prop"])
            ])
          ])
        ])
      ])
    ])
  ]))
}